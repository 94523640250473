import { DOMAIN_TO_STAGE, STAGE } from 'src/constants/StageConfig';
import { EXTERNAL_URLS, ExternalURLKey, PAGE_PATHS } from 'src/constants/Urls';

/**
 * Method to get the current stage
 */
export const getCurrentStage = (): STAGE => {
  console.log("DOMAIN_TO_STAGE: ", DOMAIN_TO_STAGE);
  console.log(JSON.stringify(DOMAIN_TO_STAGE, null, 2));
  console.log(window.location.host);
  console.log(DOMAIN_TO_STAGE[window.location.host]);
  if (DOMAIN_TO_STAGE[window.location.host]) {
    console.log("this is stupid", DOMAIN_TO_STAGE[window.location.host]);
    return DOMAIN_TO_STAGE[window.location.host];
  }
  console.log(process.env.NODE_ENV);
  if (process.env.NODE_ENV !== 'production' || !DOMAIN_TO_STAGE[window.location.host]) {
    return STAGE.ALPHA;
  }
  console.log("It is going through here");
  return DOMAIN_TO_STAGE[window.location.host];
};

/**
 * Method to return if the current stage is prod or not
 * @returns {boolean} is current stage prod or not
 */
export const isCurrentStageProd = (): boolean => {
  return getCurrentStage() === STAGE.PROD;
};

/**
 * Method to get external URLs for current stage
 */
export const getStageExternalURLs = () => {
  const currentStage = getCurrentStage();
  console.log("currentStage: ", currentStage);
  return EXTERNAL_URLS[currentStage];
};

/**
 * Method to get external URL for current stage
 */
export const getStageExternalURL = (externalUrlKey: ExternalURLKey): string => {
  console.log(externalUrlKey);
  const urls = getStageExternalURLs();
  console.log(urls);
  return urls[externalUrlKey as string];
};

export const getTCUIDeepLink = (employeeId: string): string => {
  if (getCurrentStage() === STAGE.PROD) {
    return EXTERNAL_URLS[STAGE.PROD].TCUI_PORTAL + employeeId;
  }
  return EXTERNAL_URLS[getCurrentStage()].TCUI_PORTAL + employeeId + PAGE_PATHS.TCUI_SPOOFED_USER;
};

/**
 * Method to get DALI Case URL for current stage
 */
export const getDaliCaseLink = (caseId: string) => {
  return `${getStageExternalURLs().DALI_LINK_PREFIX}Case/${caseId}/view`;
};

/**
 * Method to get the current migration status.
 */
export const getHomeBrewMigrationStatus = (applicationConfigContext?: any): boolean => {
  if (applicationConfigContext === undefined) {
    /**
     * applicationConfigContext === undefined means function called in *.ts file.
     * not context available in the file
     */
    return localStorage.getItem('homeBrewMigration') === 'true' ?? false;
  }
  const homeBrewMigrationFlag = applicationConfigContext?.config?.allConfig?.homeBrewMigration === 'true' ?? false;
  return homeBrewMigrationFlag;
};
